import React from 'react';
import { graphql, Link, PageProps } from 'gatsby';
import { FluidObject } from 'gatsby-image';

import Heading from '../components/heading';
import Layout from '../components/layout';
import SEO from '../components/seo';

type Props = Queries.NewsArticlesQuery;

const NewsIndex = ({ data }: PageProps<Props>) => {
  const { nodes: posts } = data.allMarkdownRemark;

  return (
    <Layout>
      <Heading
        title="Blog"
        image={(data.background?.childImageSharp?.fluid as FluidObject) ?? undefined}
      />
      <div className="mx-auto max-w-screen-xl px-4 pb-20 pt-16 sm:px-6 lg:px-8 lg:pb-28 lg:pt-12">
        <div className="grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
          {posts.map((post) => {
            const title = post.frontmatter.title ?? post.fields.slug;

            return (
              <article key={post.id}>
                <header>
                  {post.frontmatter.date && (
                    <p className="text-sm leading-5 text-gray-500">
                      <time dateTime={post.frontmatter.date}>{post.frontmatter.formatedDate}</time>
                    </p>
                  )}
                  <Link className="block" to={post.fields.slug ?? ''}>
                    <h3 className="mt-2 text-xl font-semibold leading-7 text-gray-900">{title}</h3>
                  </Link>
                </header>
                <section>
                  {/* eslint-disable react/no-danger */}
                  <p
                    className="mt-3 text-base leading-6 text-gray-500"
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description ?? post.excerpt ?? '',
                    }}
                  />
                  {/* eslint-enable react/no-danger */}
                </section>
                <div className="mt-3">
                  <Link
                    className="text-base font-semibold leading-6 text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500"
                    to={post.fields?.slug ?? ''}
                  >
                    Read full story
                  </Link>
                </div>
              </article>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export const Head = () => <SEO title="Blog" />;

export default NewsIndex;

export const pageQuery = graphql`
  query NewsArticles {
    allMarkdownRemark(
      filter: { fields: { slug: { glob: "/blog/*" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt(pruneLength: 250)
        id
        fields {
          slug
        }
        frontmatter {
          title
          date
          formatedDate: date(formatString: "MMMM DD, YYYY")
          description
        }
      }
    }
    background: file(relativePath: { eq: "backgrounds/IMG_3658.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
